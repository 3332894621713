import React from "react";
import {capitalize, Card, Grid} from "@mui/material";

const CardInterazione = ({card}) => (
    <Grid item xs={12} md={6} lg={4}>
        <Card variant={'come'} sx={{background:card.bkg, p:3, minHeight:'140px', display:'flex', alignItems:'center'}}>
            <Grid container alignItems={"center"} justifyContent={"space-around"} alignContent={"center"} spacing={3}>
                <Grid item xs={2.5}>
                    <img width={'100%'} src={require(`../../assets/images/card-interazione/${card.image}.png`)}/>
                </Grid>
                <Grid item xs={9.5} style={{textAlign:"left"}}  height={'100%'}>
                    <p style={{fontFamily:'Proto Grotesk', fontSize:'1.538rem', fontWeight:400, margin:0}}>{capitalize(card.title)}</p>
                    <p style={{fontFamily:'Forever Grotesk', fontSize: '1.313rem', lineHeight:'1.5rem', fontWeight:400, margin:0, opacity:'0.8'}}>{card.content}</p>
                </Grid>
            </Grid>
        </Card>
    </Grid>
)


export default CardInterazione
