import React from "react";
import {Box, Button, Stack, useMediaQuery} from "@mui/material";
import main from '../assets/images/main.png'
import ComeText from "./Come/ComeText";
import MainVisual from "./MainVisual";
import {Trans, useTranslation} from "react-i18next";

const MainSection = ({handlePanoramica}) => {
    const {t} = useTranslation()
    const matches = useMediaQuery('(min-width:900px)');
    const mobile = useMediaQuery('(min-width:767px)');
    return (
        <Stack direction={"column"} style={{height: matches ? 'max(100vh, 820px)' : 'calc(100vh - 102px)', position: 'relative'}} alignItems={"center"} justifyContent={"space-between"}>
            <Box sx={{pt:3}}>
                <p style={{fontFamily: 'Proto Grotesk', fontSize: '3.8rem', lineHeight:'3.8rem', fontWeight: matches ? 400 : 700, marginBottom: '1.6rem', marginTop: matches ? '100px' : '0'}}>
                    {t('main.title')}
                </p>
            </Box>
            <Box sx={{textAlign: "center"}}>
                <p style={{
                    fontFamily: 'Forever Grotesk',
                    fontSize: 'min(4.5vw, 1.563rem)',
                    fontWeight: 400,
                    opacity: '0.82',
                    maxWidth: '720px',
                    margin: "auto"
                }}>
                    <Trans i18nKey={('main.subtitle-1')} components={{1: <Box component={'br'} sx={{display: {xs: 'block', md:'none'}}}/>}}/>
                </p>
            </Box>
            <Box sx={{textAlign: "center"}}>
                <p style={{
                    fontFamily: 'Forever Grotesk',
                    fontSize: 'min(4.5vw, 1.563rem)',
                    lineHeight: 'min(5.5vw, 2.563rem)',
                    fontWeight: 400,
                    opacity: '0.82',
                    maxWidth: '670px',
                    margin: "auto",
                    marginTop:'1rem'
                }}>
                    <Trans i18nKey={('main.subtitle-2')} components={{1: <Box component={'br'} sx={{display: {xs: 'block', md:'none'}}}/>}}/>
                </p>
            </Box>
            <Box sx={{textAlign: "center"}}>
                <p style={{
                    fontFamily: 'Forever Grotesk',
                    fontSize: 'min(4.5vw, 1.563rem)',
                    lineHeight: 'min(5.5vw, 2.563rem)',
                    fontWeight: 400,
                    opacity: '0.82',
                    maxWidth: '670px',
                    margin: "auto",
                    marginTop:'1rem'
                }}>
                    <Trans i18nKey={('main.subtitle-3')} components={{1: <Box component={'br'} sx={{display: {xs: 'block', md:'none'}}}/>}}/>
                </p>
            </Box>
            <Box sx={{pt:3}}>
                <Button
                    onClick={handlePanoramica}
                    style={{maxWidth: '308px', position: 'relative'}}
                    //style={{maxWidth: '308px', position: !mobile ? 'absolute' : 'relative', bottom: !mobile && '3rem'}}
                    variant={"contained"}>
                    {t('main.discover')}
                </Button>
            </Box>
            <div style={{bottom: 0, zIndex: -99, width:'100%'}}>
                <MainVisual/>
            </div>

        </Stack>
    )
}

export default MainSection
