import React from "react";
import {InlineWidget} from "react-calendly";
import {Box, Container} from "@mui/material";
import Typography from "@mui/material/Typography";
import {useTranslation} from "react-i18next";

const RichiediDemo = () => {
    const {t} = useTranslation()

    return (
        <Container>
            <Box sx={{width:'min(15rem, 100%)', margin:'auto', pt:4}}>
                <img style={{width:'100%'}} src={require('../assets/images/logo_header_by_plesh.png')}/>
            </Box>
            <Box sx={{pt:4}}>
                <Typography textAlign={'center'} fontSize={20}>
                    {t('thankYouPage.text1')}
                    <br/>
                    {t('thankYouPage.text2')}
                </Typography>
            </Box>
            <Box style={{height:'75vh'}}>
                <InlineWidget styles={{height:'100%'}} url="https://calendly.com/plesh/call-informativa-2ndstage"/>
            </Box>
        </Container>
    );
};

export default RichiediDemo;
