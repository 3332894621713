import React from "react";
import {useTranslation} from "react-i18next";
import {Box} from "@mui/material";

const ComeTitle = () => {
    const {t} = useTranslation()
    return (
        <Box sx={{paddingBottom: '2rem', px:2}}>
            <p style={{
                fontFamily: 'Proto Grotesk',
                fontSize: '2.688rem',
                fontWeight: 400,
                textAlign: 'center',
                lineHeight: '2.813rem',
                marginBottom: '1rem'
            }}>
                {t('come.title-1')}
                <br/>
                {t('come.title-2')}
            </p>
        </Box>
    )
}

export default ComeTitle
