import React from "react";
import {Box, Card, CardContent, CardMedia, Container, Grid} from "@mui/material";
import {Trans, useTranslation} from "react-i18next";
import Typography from "@mui/material/Typography";


const CardBlock = () => {
    const {t} = useTranslation()
    const cards = [
        {
            title: t('card-block.1.title'),
            content:t('card-block.1.content'),
            image: 'card_1'
        },
        {
            title: t('card-block.2.title'),
            content:t('card-block.2.content'),
            image: 'card_2'
        },
        {
            title: t('card-block.3.title'),
            content:t('card-block.3.content'),
            image: 'card_3'
        },
    ]

    const RenderCards = () => {
        let ca = []
        cards.map((c,i) =>
            ca.push(
                <Grid item xs={12} md={6} lg={4} key={i} sx={{p:2}}>
                    <Card variant={'gradient'} style={{height:'100%'}}>
                        <div style={{padding:'30px 0 0 0'}}>
                            <img style={{maxWidth:'163px'}} src={require(`../assets/images/${c.image}.png`)}/>
                        </div>
                        <CardContent style={{maxWidth:'360px', margin:'auto'}}>
                            <p style={{fontSize: '1.75rem', lineHeight:'2.438rem', fontWeight:400, fontFamily:'Proto Grotesk', marginTop:'0'}}>
                                <Trans i18nKey={c.title}/>
                            </p>
                            <p style={{fontSize: '1.313rem', lineHeight:'1.5rem', opacity:'0.6', fontWeight:400, fontFamily:'Forever Grotesk'}}>{c.content}</p>
                        </CardContent>
                    </Card>
                </Grid>
            )
        )
        return ca
    }
    return(
        <Box>
            <Box sx={{px:2}}>
                <p style={{
                    fontFamily: 'Proto Grotesk',
                    fontSize: '2.688rem',
                    fontWeight: 400,
                    textAlign: 'center',
                    lineHeight: '2.813rem',
                    marginBottom: '0'
                }}>
                    <Trans i18nKey={('card-block.title')} components={{1: <Box component={'br'} sx={{display: {xs: 'block', md:'none'}}}/>}}/>
                </p>
            </Box>
            <Grid sx={{py:5}} container direction={"row"} style={{maxWidth:'1326px', margin:'auto', width:'90%'}} justifyContent={"center"} alignContent={"center"}>
                <RenderCards/>
            </Grid>
        </Box>
    )
}

export default CardBlock
