import React, {useRef} from "react";
import MainSection from "../components/MainSection";
import CardBlock from "../components/CardBlock";
import Come from "../components/Come";
import BandaDemo from "../components/BandaDemo";
import CompagnoPerfetto from "../components/CompagnoPerfetto";
import CardDemo from "../components/CardDemo";
import Footer from "../components/Footer";
import VideoBox from "../components/VideoBox";
import RivoltoAChi from "../components/RivoltoAChi";
import ComeInteragiscono from "../components/ComeInteragiscono";
import Header from "../components/Header";
import {Box, useMediaQuery} from "@mui/material";
import {LangSwitcher} from "../components/LangSwitcher";
import logo_mobile_big from "../assets/images/logo_header_mobile_big_by_plesh.png";

const Home = () => {
    const matches = useMediaQuery('(min-width:1000px)');
    const panoramica = useRef()
    const comeFunziona = useRef()
    const features = useRef()
    const provalo = useRef()

    function handleComeFunziona() {
        comeFunziona.current.scrollIntoView({behavior: 'smooth'})
    }

    function handleFeatures() {
        features.current.scrollIntoView({behavior: 'smooth'})
    }

    function handleProvalo() {
        provalo.current.scrollIntoView({behavior: 'smooth'})
    }

    function handlePanoramica() {
        panoramica.current.scrollIntoView({behavior: 'smooth'})
    }

    return(
        <div>
            {matches && <Header handleProvalo={handleProvalo} handlePanoramica={handlePanoramica}
                                handleComeFunziona={handleComeFunziona} handleFeatures={handleFeatures}/>}
            <Box sx={{position: 'absolute', top: '1rem', right: '2rem', display: matches ? 'none' : 'block',}}>
                <LangSwitcher/>
            </Box>
            <img style={{display: matches ? 'none' : 'flex', margin: '3rem auto 0 auto', maxWidth: '126px'}}
                 src={logo_mobile_big}/>
            <MainSection handlePanoramica={handlePanoramica}/>
            <RivoltoAChi panoramica={panoramica}/>
            <CardBlock/>
            <Come comeFunziona={comeFunziona}/>
            <BandaDemo handleProvalo={handleProvalo}/>
            <ComeInteragiscono features={features}/>
            <VideoBox/>
            <div style={{background: 'linear-gradient(180deg, rgba(32,32,70,1) 0%, rgba(19,19,49,1) 100%)'}}>
                <CompagnoPerfetto/>
                <CardDemo provalo={provalo}/>
                <Footer/>
            </div>

        </div>
    )
}

export default Home
