import React, {useEffect} from "react";
import {Box, Stack} from "@mui/material";
import Typewrite from "./CompagnoPerfetto/Typewrite";
import Typewriter from "typewriter-effect";
import {Swiper, SwiperSlide} from "swiper/react";
import {Autoplay} from "swiper";
import {useTranslation} from "react-i18next";

const images = [1,2,3,4,5,6,7,8]


const CompagnoPerfetto = () => {
    const {t,i18n} = useTranslation()
    const [rendering, setRendering] = React.useState(false)

    useEffect(() => {
        setRendering(!rendering)
    }, [i18n.language])

    return (
        <Stack direction={"column"}>
            <Box sx={{px:2}}>
                <p style={{fontFamily: 'Proto Grotesk', fontSize: 'min(7.5vw, 2.688rem)', lineHeight: 'min(9.3vw, 2.813rem)', fontWeight: 400, marginBottom:'0rem'}}>
                    2ndStage
                    <br/>
                    {t('compagno.title')}
                </p>
            </Box>
            <Typewrite/>
            <Swiper
                style={{marginTop:'2rem'}}
                slidesPerView={1.5}
                spaceBetween={5}
                breakpoints={{
                    500: {
                        slidesPerView: 2.2,
                        spaceBetween: 20,
                    },
                    640: {
                        slidesPerView: 2.6,
                        spaceBetween: 20,
                    },
                    768: {
                        slidesPerView: 3.5,
                        spaceBetween: 40,
                    },
                    900: {
                        slidesPerView: 4,
                        spaceBetween: 5,
                    },
                    1000: {
                        slidesPerView: 4,
                        spaceBetween: 5,
                    },
                    1100: {
                        slidesPerView: 4.5,
                        spaceBetween: 5,
                    },
                    1200: {
                        slidesPerView: 4.5,
                        spaceBetween: 5,
                    },

                    1300: {
                        slidesPerView: 5.5,
                        spaceBetween: 5,
                    },
                    1400: {
                        slidesPerView: 6,
                        spaceBetween: 5,
                    },
                    1500: {
                        slidesPerView: 6.5,
                        spaceBetween: 5,
                    },
                    1600: {
                        slidesPerView: 7,
                        spaceBetween: 5,
                    },
                    1720: {
                        slidesPerView: 7.5,
                        spaceBetween: 5,
                    },
                    2000: {
                        slidesPerView: 9.5,
                        spaceBetween: 5,
                    },
                }}

                className="mySwiper"
                autoplay={{
                    delay: 1,
                    disableOnInteraction: false
                }}
                allowTouchMove={false}
                speed={5000}
                loop={true}
                modules={[Autoplay]}
            >
                {images.map(i => <SwiperSlide key={i}>
                    <img height={'538px'} src={require(`../assets/images/compagno-perfetto/${i}.png`)}/>
                </SwiperSlide>)}

            </Swiper>
        </Stack>
    )
}

export default CompagnoPerfetto
