import React from "react";
import {
    Box,
    Button,
    Card,
    CircularProgress,
    Container,
    Paper,
    Stack,
    TextField,
    useMediaQuery,
    useTheme
} from "@mui/material";
import logo from '../assets/images/logo_white.png'
import arrow from '../assets/images/arrow.png'
import mano from '../assets/images/mano.png'
import bkg from '../assets/images/demo-card-bkg.png'
import {Trans, useTranslation} from "react-i18next";
import Typography from "@mui/material/Typography";
import {API_URL} from "../config";
import axios from "axios";
import {useNavigate} from "react-router-dom";

const CardDemo = ({provalo}) => {
    const matches = useMediaQuery('(min-width:767px)');
    const [sending, setSending] = React.useState(false)
    const [success, setSuccess] = React.useState(false)
    const navigate = useNavigate()
    const [dati, setDati] = React.useState({
        nome: '',
        email: '',
        telefono: ""
    })

    const handleChange = (event, field) => {
        setDati({
            ...dati,
            [field]: event.target.value
        })
    }

    const {t, i18n} = useTranslation()

    const sendDati = async (event) => {
        event.preventDefault()
        setSending(true)
        await axios.post(`${API_URL}/2ndStage/register`, {...dati, lang: i18n.language?.slice(0,2) ?? 'it'})
            .then(res => {
                setSuccess(true)
                navigate('/grazie-informazioni-2ndstage')
            })
        setSending(false)
    }

    return (
        <Card variant={'demo'}
              ref={provalo}
              sx={{width: '95%', maxWidth: '1330px', margin: '80px auto', position: 'relative', zIndex: 0}}>
            <Stack direction={"column"} alignItems={"center"} justifyContent={matches ? "center" : "space-between"}
                   height={'100%'} zIndex={'90'} sx={{height:'100%', pt:{xs:0, md:4}}}>
                <img style={{maxWidth: '82px', paddingTop: matches ? '0' : '40px'}} src={logo}/>
                <p style={{
                    fontSize: 'min(12vw, 4.188rem)',
                    fontWeight: 400,
                    fontFamily: 'Proto Grotesk',
                    margin: '1rem 0',
                    lineHeight: 'min(13vw, 4.188rem)'
                }}>{t('card.title')}</p>
                <p style={{
                    fontSize: 'min(5vw, 1.688rem)',
                    fontWeight: 400,
                    fontFamily: 'Forever Grotesk',
                    color: '#000',
                    lineHeight: 'min(6vw, 2rem)',
                    margin: '0 0 1rem 0',
                    padding: '5px'
                }}>
                    <Trans i18nKey={('card.text-1')} components={{1: <Box component={'br'} sx={{display: {xs: 'block', md:'none'}}}/>}}/>
                    <br/>
                    <br/>
                    <Trans i18nKey={('card.text-2')} components={{1: <Box component={'br'} sx={{display: {xs: 'block', md:'none'}}}/>}}/>
                </p>
                <Container maxWidth={'xs'}>
                    <Stack alignItems={'center'} component={'form'} onSubmit={sendDati} sx={{width: '100%'}}>
                        <Stack mt={3} spacing={1} sx={{width: '100%'}}>
                            <TextField fullWidth variant={'outlined'}
                                       placeholder={t('card.name')+'*'}
                                       value={dati.nome}
                                       required
                                       onChange={(event) => handleChange(event, 'nome')}
                                       type={'text'}/>
                            <TextField fullWidth variant={'outlined'}
                                       placeholder={t('card.email')+'*'}
                                       value={dati.email}
                                       required
                                       onChange={(event) => handleChange(event, 'email')}
                                       type={'email'}/>
                            <TextField fullWidth variant={'outlined'}
                                       placeholder={t('card.phone')}
                                       value={dati.telefono}
                                       onChange={(event) => handleChange(event, 'telefono')}/>
                            <Box sx={{pt: 2, pb: {xs:4, md:0}}}>
                                {sending ?
                                    <Box sx={{width:'100%'}}>
                                        <Box sx={{textAlign: 'center'}}>
                                            <CircularProgress sx={{color:'white'}}/>
                                        </Box>
                                    </Box>
                                    :
                                    success ?
                                        <Box>
                                            <Typography variant={'h6'} textAlign={'center'}>{t('card.success')}</Typography>
                                        </Box>
                                        :
                                        <div style={{position: "relative", textAlign: 'center', zIndex: 91}}>
                                            <img style={{
                                                position: 'absolute',
                                                top: '50%',
                                                left: '50%',
                                                width: '57px',
                                                height: '57px',
                                                marginTop: '8px',
                                                marginLeft: '-28px',
                                                zIndex: 96
                                            }} src={arrow}/>
                                            <Button variant={'bottomDemo'} type={'submit'}
                                                    style={{
                                                        zIndex: 95,
                                                        textTransform: 'uppercase'
                                                    }}>{t('shared.cta')}</Button>
                                        </div>
                                }
                            </Box>
                        </Stack>
                        {!matches && <img style={{position: "relative", maxWidth: '250px', paddingTop: '20px'}}
                                          src={mano}/>}
                    </Stack>
                </Container>
            </Stack>
            {
                matches && <img style={{
                    position: "absolute",
                    width: 'min(450px, 40vw)',
                    right: '0',
                    marginRight: '-65px',
                    bottom: '0',
                    zIndex: '-1'
                }}
                                src={mano}/>
            }
            {
                matches &&
                <img style={{position: "absolute", width: '100%', top: 0, left: 0, zIndex: '-2', opacity: '0.07'}}
                     src={bkg}/>
            }
        </Card>
    )
}

export default CardDemo
