import React from "react";
import video from '../assets/videos/video.mp4'

const VideoBox = () => {
    return (
        <div style={{padding: '80px 0', display:'flex', justifyContent:'center'}}>
            <div style={{
                width: '680px',
                height: '410px',
                maxWidth: '90%',
                borderRadius: '3.2rem',
                border: '1px solid #4C4C7E',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                boxShadow:'0 0 50px rgba(63, 63, 172, 0.11)'
            }}>
                <div style={{
                    width: '660px',
                    height: '390px',
                    borderRadius: '2.7rem',
                    margin: '0.4rem 0.5rem',
                    overflow: 'hidden',
                    position:'relative'
                }}>
                    <video className={'videoBox'} autoPlay loop height='100%' muted playsInline>
                        <source src={video}/>
                    </video>
                </div>
            </div>
        </div>
    )
}

export default VideoBox
