import {createTheme, responsiveFontSizes} from "@mui/material";

export const pleshDark = responsiveFontSizes(createTheme({
    typography: {
        fontFamily: ['Proto Grotesk']
    },

    components:{
        MuiAppBar: {
            styleOverrides:{
                root:{
                    background:'#131331',
                    borderRadius:'0',
                    borderColor:'#131331',
                    boxShadow:'none',
                    '&:hover': {
                        background:'#131331',
                        boxShadow:'none'
                    }
                }
            }
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    color: "#252a33",
                },
            }
        },
        MuiInputBase: {
            styleOverrides: {
                root: {
                    color: "#252a33",
                    "& input": {
                        textAlign: "center"
                    }
                },
            }
        },
        MuiTextField: {
            styleOverrides: {
                root: {
                    background: 'white',
                    borderRadius: '1rem',
                    borderColor: '#252a33',
                    boxShadow: 'none',
                    '& fieldset': {
                        borderRadius: `1rem`,
                        textAlign: "center"
                    },
                }
            }
        },

        MuiPaper: {
            styleOverrides:{
                root: {
                    borderRadius:'1rem',
                },
                gradient:{
                    borderRadius:'2rem',
                    background: 'linear-gradient(-45deg, rgba(28, 28, 61,1) 0%, rgba(17, 17, 44,1) 100%) 0',
                    border: '2px solid #4C4C7E',
                    transition:'0.5s ease',
                    '&:hover': {
                        background: 'linear-gradient(-45deg, rgba(28, 28, 61,1) 20%, rgba(17, 17, 44,1) 100%) 100% / 200%',
                        boxShadow:'0px 0px 13px 0px rgba(76,76,126,0.72)'
                    },
                },
                come:{
                    //background: 'linear-gradient(-45deg, rgba(28, 28, 61,1) 0%, rgba(17, 17, 44,1) 100%) 0',
                    border: '1px solid #fff',
                    borderRadius:'2.5rem',
                    transition:'0.5s ease',
                    '&:hover': {
                        //background: 'linear-gradient(-45deg, rgba(28, 28, 61,1) 20%, rgba(17, 17, 44,1) 100%) 100% / 200%',
                        boxShadow:'0px 0px 30px 0px rgba(76,76,126,0.72)'
                    },
                },
                swipe:{
                    borderRadius:'2rem',
                    background: 'linear-gradient(0deg, rgba(28, 28, 61,1) 0%, rgba(17, 17, 44,1) 100%) 0',
                    border: '2px solid #393462',
                    transition:'0.5s ease',
                    boxShadow:'0px 0px 20px 0px rgba(0,0,0,0.46)'
                },
                demo: {
                    borderRadius:'5rem',
                    background: 'linear-gradient(0deg, rgba(234,77,149,1) 0%, rgba(231,110,81,1) 100%)',
                    backgroundRepeat:'no-repeat',
                    boxShadow: '0 0 70px rgba(229, 23, 133, 0.26)',
                    minHeight:'670px',
                    '@media (max-width: 767px)': {
                        background: 'linear-gradient(90deg, rgba(231,110,81,1) 0%, rgba(234,77,149,1) 100%)',
                        minHeight: '0'
                    },
                }
            }
        },
        MuiButton:{
            styleOverrides: {
                root:{
                    borderRadius:'2rem',
                    fontFamily:'Forever Grotesk',
                    fontWeight:'700',
                    textTransform:'none',
                    fontSize:'1.438rem',
                    padding:'8px 50px'
                },
                contained: {
                    color:'#fff',
                    backgroundColor: 'rgba(234,77,149,1)',
                    background: 'linear-gradient(90deg, rgba(231,110,81,1) 0%, rgba(234,77,149,1) 100%)'
                },
                outlined: {
                    color:'#E85D74',
                    border: '2px solid #E85D74',
                    background:'transparent',
                    '&:hover': {
                        color:'white',
                        boxShadow: 'inset 18em 0 0 0 #E85D74',
                        background:'transparent',
                        border: '2px solid #E85D74',
                    }
                },
                bottomDemo: {
                    color:'#FFC3C8',
                    background:'linear-gradient(-45deg, rgba(28,28,61,1) 0%, rgba(17,17,44,1) 100%)',

                    '&:hover': {
                        boxShadow: '0 0 15px rgba(0,0,0,0.29)',
                        color:'#FFC3C8',
                        background:'linear-gradient(-45deg, rgba(28,28,61,1) 0%, rgba(17,17,44,1) 100%)',
                    }
                }
            }
        }
    },

    palette: {
        mode: 'dark',
        primary: {
            main: '#E85D74',
        },
        secondary: {
            main: '#263238',
        },
        background: {
            default:'#131331',
        },
        accent: {
            main: '#e6fc69',
        },
        text: {
            primary: '#eceff1',
        },
    },
    customColors: {
        clickTo: {
            main: '#FFEB3B',
        },
        wordcloud: {
            main: 'linear-gradient(90deg, rgba(234,77,149,1) 0%, rgba(231,110,81,1) 100%)'
        },
        quiz: {
            main: '#388E3C'
        },
        siNo: {
            main: '#D32F2F'
        },
        poll: {
            main: 'linear-gradient(90deg, rgba(121,216,161,1) 0%, rgba(253,228,81,1) 100%)'
        }
    }
}));
