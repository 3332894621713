import React from "react";
import {Button, Stack} from "@mui/material";
import {useTranslation} from "react-i18next";

const BandaDemo = ({handleProvalo}) => {
    const {t} = useTranslation()
    return (
        <Stack justifyContent={"center"} direction={"column"} style={{
            width: '100%',
            height: '200px',
            background: 'linear-gradient(90deg, rgba(231,110,81,1) 0%, rgba(234,77,149,1) 100%)',
        }}>
            <p style={{
                font: '1.563rem Forever Grotesk',
                color: '#fff',
                opacity: '0.82',
                padding: '0 1rem',
                margin: '0 0 1.5rem 0'
            }}>
                {t('demo.title-1')}
                <br />
                {t('demo.title-2')}</p>
            <Button onClick={handleProvalo} style={{maxWidth: '360px', margin: '0 auto', textTransform:'uppercase'}} variant={"contained"}>
                {t('shared.cta')}
            </Button>
        </Stack>
    )
}

export default BandaDemo
