import React from "react";
import {Box, Card, CardContent, Container, Grid, Stack} from "@mui/material";
import visual from '../assets/images/come-visual.png'
import ComeText from "./Come/ComeText";
import ComeTitle from "./Come/ComeTitle";
import ComeCard from "./Come/ComeCard";
import {Swiper, SwiperSlide} from "swiper/react";
import {Pagination} from "swiper";
import {useTranslation} from "react-i18next";

const Come = ({comeFunziona}) => {
    const {t} = useTranslation()
    const testi = [
        {
            title: t('come.cards.1.title'),
            text: t('come.cards.1.text'),
            image: 'come-1'
        },
        {
            title: t('come.cards.2.title'),
            text: t('come.cards.2.text'),
            image: 'come-2'
        },
        {
            title: t('come.cards.3.title'),
            text: t('come.cards.3.text'),
            image: 'come-3'
        },
    ]

    return (
        <div style={{background: '#202046', padding: '50px 0'}} ref={comeFunziona}>
            <Stack direction={"column"} sx={{display: {xs: 'none', md: 'block'}}}>
                <ComeTitle/>
                <img style={{width: '100%', maxWidth: '1321px', margin: 'auto'}} src={visual}/>
                <Container style={{maxWidth: '1326px', width: '100%'}}>
                    <Grid container>
                        {testi.map((t, i) => <ComeText text={t.text} title={t.title} key={i} index={i + 1}/>)}
                    </Grid>
                </Container>
            </Stack>
            <Box sx={{display: {xs: 'block', md: 'none'}}}>
                <ComeTitle/>
                <Swiper
                    slidesPerView={1}
                    spaceBetween={10}
                    pagination={{
                        clickable: true,
                    }}
                    modules={[Pagination]}
                    className="mySwiper"
                    style={{
                        padding: '1rem 2rem',
                        "--swiper-pagination-color": "#E85D74",
                        "--swiper-pagination-bullet-size":"18px"
                }}
                >
                    {testi.map((t, i) =>
                        <SwiperSlide>
                            <ComeCard text={t.text} title={t.title} image={t.image} key={i}
                                      index={i + 1}/>
                        </SwiperSlide>)}
                </Swiper>
            </Box>
        </div>
    )
}

export default Come
