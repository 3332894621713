import React from "react";
import {Container} from "@mui/material";

const Privacy = () => (
    <Container className="privacy-container">
        <p className="privacy-title">
            Cookie Policy
        </p>
        <p>
            I Cookie o altri Identificatori sono costituiti da porzioni di codice collocate sul dispositivo dell'Utente
            che assistono il Titolare nell'erogazione del Servizio in base alle finalità
            descritte.<br/><br/>
            Alcune delle finalità di utilizzo degli Identificatori potrebbero, inoltre, necessitare del consenso
            dell’Utente. Qualora sia già stato prestato, il consenso può essere revocato
            liberamente in qualsiasi momento seguendo le istruzioni contenute in questo documento.
        </p>
        <p className="privacy-title">
            Attività strettamente necessarie a garantire il Funzionamento di questo Sito Web e la fornitura del Servizio
        </p>
        <p>
            Questo Sito Web usa Cookie o altri Identificatori per svolgere attività che sono strettamente necessarie a
            garantire il funzionamento o la fornitura del Servizio e, pertanto, non
            richiedono il consenso degli Utenti. Gli Utenti possono disabilitare tali Identificatori modificando le
            impostazioni del proprio browser o del proprio dispositivo così come descritto
            più dettagliatamente nel presente documento tuttavia ciò potrebbe influire negativamente sulle funzioni
            operative di base o comportare l'indisponibilità del Servizio.
        </p>
        <p className="privacy-title">
            Ottimizzazione e distribuzione del traffico
        </p>
        <p>
            Questo tipo di servizi permette a questo Sito Web di distribuire i propri contenuti tramite dei server
            dislocati sul territorio e di ottimizzare le prestazioni della stessa.<br/>
            I Dati Personali trattati dipendono dalle caratteristiche e della modalità d’implementazione di questi
            servizi, che per loro natura filtrano le comunicazioni fra questo Sito Web ed il
            browser dell’Utente.<br/>
            Vista la natura distribuita di questo sistema, è difficile determinare i luoghi in cui vengono trasferiti i
            contenuti, che potrebbero contenere Dati Personali dell’Utente.
        </p>
        <p className="privacy-title">
            Altre Attività
        </p>
        <p className="privacy-title">
            Interazioni e funzionalità semplici
        </p>
        <p>
            Questo Sito Web usa Cookie o altri Identificatori per abilitare semplici interazioni e funzionalità che
            permettono agli Utenti di accedere a determinate risorse del Servizio e gli
            consentono di comunicare più facilmente con il Titolare.
        </p>
        <p className="privacy-title">
            Contattare l'Utente
        </p>
        <p>
            Mailing list o newsletter (Questo Sito Web)<br/>
            Con la registrazione alla mailing list o alla newsletter, l’indirizzo email dell’Utente viene
            automaticamente inserito in una lista di contatti a cui potranno essere trasmessi messaggi
            email contenenti informazioni, anche di natura commerciale e promozionale, relative a questo Sito Web.
            L'indirizzo email dell'Utente potrebbe anche essere aggiunto a questa lista come
            risultato della registrazione a questo Sito Web o dopo aver effettuato un acquisto.<br/>
            Dati Personali trattati: CAP, città, cognome, Cookie, data di nascita, Dati di utilizzo, email, indirizzo,
            nazione, nome, numero di telefono, professione, provincia, ragione sociale e
            sesso.
        </p>
        <p className="privacy-title">
            Registrazione ed autenticazione
        </p>
        <p>
            Con la registrazione o l’autenticazione l’Utente consente all’Applicazione di indentificarlo e di dargli
            accesso a servizi dedicati.<br/>
            A seconda di quanto indicato di seguito, i servizi di registrazione e di autenticazione potrebbero essere
            forniti con l’ausilio di terze parti. Qualora questo avvenga, questa
            applicazione potrà accedere ad alcuni Dati conservati dal servizio terzo usato per la registrazione o
            l’identificazione.
        </p>
        <p className="privacy-title">
            Esperienza migliorata
        </p>
        <p>
            Questo Sito Web usa Cookie o altri Identificatori per fornire una user experience personalizzata migliorando
            la qualità della gestione delle impostazioni e consentendo l'interazione
            con network e piattaforme esterne.
        </p>
        <p className="privacy-title">
            Commento dei contenuti
        </p>
        <p>
            I servizi di commento consentono agli Utenti di formulare e rendere pubblici propri commenti riguardanti il
            contenuto di questo Sito Web.<br/>
            Gli Utenti, a seconda delle impostazioni decise dal Titolare, possono lasciare il commento anche in forma
            anonima. Nel caso tra i Dati Personali rilasciati dall’Utente ci sia l’email,
            questa potrebbe essere utilizzata per inviare notifiche di commenti riguardanti lo stesso contenuto. Gli
            Utenti sono responsabili del contenuto dei propri commenti.<br/>
            Nel caso in cui sia installato un servizio di commenti fornito da soggetti terzi, è possibile che, anche nel
            caso in cui gli Utenti non utilizzino il servizio di commento, lo stesso
            raccolga dati di traffico relativi alle pagine in cui il servizio di commento è installato.
        </p>
        <p className="privacy-subtitle">
            Facebook Comments (Facebook, Inc.)
        </p>
        <p className="privacy-subtext">
            Facebook Comments è un servizio gestito da Facebook, Inc. che consente all’Utente di lasciare propri
            commenti e condividerli all’interno della piattaforma Facebook.<br/>
            Dati Personali trattati: Cookie; Dati di utilizzo.<br/>
            Luogo del trattamento: USA – Privacy Policy. Soggetto aderente al Privacy Shield.
        </p>
        <p className="privacy-title">
            Interazione con social network e piattaforme esterne
        </p>
        <p>
            Questo tipo di servizi permette di effettuare interazioni con i social network, o con altre piattaforme
            esterne, direttamente dalle pagine di questo Sito Web.<br/>
            Le interazioni e le informazioni acquisite da questo Sito Web sono in ogni caso soggette alle impostazioni
            privacy dell’Utente relative ad ogni social network.<br/>
            Questo tipo di servizio potrebbe comunque raccogliere dati sul traffico per le pagine dove il servizio è
            installato, anche quando gli Utenti non lo utilizzano.<br/>
            Si raccomanda di disconnettersi dai rispettivi servizi per assicurarsi che i dati elaborati su questo Sito
            Web non vengano ricollegati al profilo dell'Utente.
        </p>
        <p className="privacy-subtitle">
            Pulsante Tweet e widget sociali di Twitter (Twitter, Inc.)
        </p>
        <p className="privacy-subtext">
            Il pulsante Tweet e i widget sociali di Twitter sono servizi di interazione con il social network Twitter,
            forniti da Twitter, Inc.<br/>
            Dati Personali trattati: Cookie e Dati di utilizzo.<br/><br/>
            Luogo del trattamento: Stati Uniti – Privacy Policy. Soggetto aderente al Privacy Shield.
        </p>
        <p className="privacy-subtitle">
            Pulsante Mi Piace e widget sociali di Facebook (Facebook, Inc.)
        </p>
        <p className="privacy-subtext">
            Il pulsante “Mi Piace” e i widget sociali di Facebook sono servizi di interazione con il social network
            Facebook, forniti da Facebook, Inc.<br/>
            Dati Personali trattati: Cookie e Dati di utilizzo.<br/><br/>
            Luogo del trattamento: Stati Uniti – Privacy Policy. Soggetto aderente al Privacy Shield.
        </p>
        <p className="privacy-subtitle">
            Pulsante e widget sociali di Linkedin (LinkedIn Corporation)
        </p>
        <p className="privacy-subtext">
            Il pulsante e i widget sociali di LinkedIn sono servizi di interazione con il social network Linkedin,
            forniti da LinkedIn Corporation.<br/>
            Dati Personali trattati: Cookie e Dati di utilizzo.<br/><br/>
            Luogo del trattamento: Stati Uniti – Privacy Policy. Soggetto aderente al Privacy Shield.
        </p>
        <p className="privacy-subtitle">
            Visualizzazione di contenuti da piattaforme esterne
        </p>
        <p className="privacy-subtext">
            Questo tipo di servizi permette di visualizzare contenuti ospitati su piattaforme esterne direttamente dalle
            pagine di questo Sito Web e di interagire con essi.<br/>
            Nel caso in cui sia installato un servizio di questo tipo, è possibile che, anche nel caso gli Utenti non
            utilizzino il servizio, lo stesso raccolga dati di traffico relativi alle
            pagine in cui è installato.
        </p>
        <p className="privacy-subtitle">
            Widget Instagram (Instagram, Inc.)
        </p>
        <p className="privacy-subtext">
            Instagram è un servizio di visualizzazione di immagini gestito da Instagram, Inc. che permette a questo Sito
            Web di integrare tali contenuti all’interno delle proprie pagine.<br/>
            Dati Personali trattati: Cookie e Dati di utilizzo.<br/><br/>
            Luogo del trattamento: Stati Uniti – Privacy Policy.
        </p>
        <p className="privacy-title">
            Misurazione
        </p>
        <p>
            Questo Sito Web usa Cookie o altri Identificatori per misurare il traffico e analizzare il comportamento
            degli Utenti con l'obiettivo di migliorare il Servizio.
        </p>
        <p className="privacy-title">
            Servizi di statistica gestiti direttamente da questo Sito Web
        </p>
        <p>
            I servizi contenuti nella presente sezione sono servizi di prima parte che permettono al Titolare, tramite
            l’uso di Cookie o altri Identificatori, di gestire le statistiche
            direttamente impiegate da questo Sito Web. A seconda della legge applicabile, questi servizi potrebbero non
            richiedere il consenso dell'Utente. In tali casi gli Utenti possono
            disattivare gli Identificatori modificando le impostazioni del loro browser o dispositivo come descritto più
            dettagliatamente nel presente documento.
        </p>
        <p className="privacy-subtitle">
            Statistiche raccolte in modo diretto (Questo Sito Web)
        </p>
        <p className="privacy-subtext">
            Questo Sito Web utilizza un sistema di statistiche interno, che non coinvolge terze parti.<br/>
            Dati Personali trattati: Cookie e Dati di utilizzo.
        </p>
        <p className="privacy-title mt-5 mb-4">
            Statistica
        </p>
        <p>
            I servizi contenuti nella presente sezione permettono al Titolare del Trattamento di monitorare e analizzare
            i dati di traffico e servono a tener traccia del comportamento dell’Utente.
        </p>
        <p className="privacy-subtitle mb-3 mt-4">
            Branch Attribution (TUNE, Inc.)
        </p>
        <p className="privacy-subtext">
            Branch Attribution è un servizio di statistica fornito da Branch Metrics, Inc.<br/>
            Dati Personali trattati: Cookie, Dati di utilizzo e varie tipologie di Dati secondo quanto specificato dalla
            privacy policy del servizio.<br/><br/>
            Luogo del trattamento: USA – Privacy Policy – Opt Out.
        </p>

        <p className="privacy-subtitle mb-3 mt-4">
            Wordpress Stat (Automattic Inc.)
        </p>
        <p className="privacy-subtext">
            Wordpress Stats è un servizio di statistica fornito da Automattic Inc.<br/>
            Dati Personali trattati: Cookie e Dati di utilizzo.<br/><br/>
            Luogo del trattamento: USA – Privacy Policy.
        </p>
        <p className="privacy-subtitle mb-3 mt-4">
            Wordpress Stat (Automattic Inc.)
        </p>
        <p className="privacy-subtext">
            Google Analytics è un servizio di analisi web fornito da Google LLC (“Google”). Google utilizza i Dati
            Personali raccolti allo scopo di tracciare ed esaminare l’utilizzo di questo Sito
            Web, compilare report e condividerli con gli altri servizi sviluppati da Google.<br/>
            Google potrebbe utilizzare i Dati Personali per contestualizzare e personalizzare gli annunci del proprio
            network pubblicitario.<br/>
            Dati Personali trattati: Cookie e Dati di utilizzo.<br/><br/>
            Luogo del trattamento: Stati Uniti – Privacy Policy – Opt Out. Soggetto aderente al Privacy Shield.
        </p>
        <p className="privacy-subtitle mb-3 mt-4">
            Monitoraggio conversioni di Facebook Ads (pixel di Facebook) (Facebook, Inc.)
        </p>
        <p className="privacy-subtext">
            Il monitoraggio conversioni di Facebook Ads (pixel di Facebook) è un servizio di statistiche fornito da
            Facebook, Inc. che collega i dati provenienti dal network di annunci Facebook
            con le azioni compiute all'interno di questo Sito Web. Il pixel di Facebook monitora le conversioni che
            possono essere attribuite alle inserzioni di Facebook, Instagram e Audience
            Network.<br/>
            Dati Personali trattati: Cookie e Dati di utilizzo.<br/><br/>
            Luogo del trattamento: Stati Uniti – Privacy Policy. Soggetto aderente al Privacy Shield.
        </p>
        <p className="privacy-subtitle mb-3 mt-4">
            HubSpot Analytics (HubSpot, Inc.)
        </p>
        <p className="privacy-subtext">
            HubSpot Analytics è un servizio di statistica fornito da HubSpot, Inc.<br/>
            Dati Personali trattati: Cookie e Dati di utilizzo.<br/><br/>
            Luogo del trattamento: Stati Uniti – Privacy Policy – Opt Out .
        </p>
        <p className="privacy-title mt-5 mb-4">
            Test di performance di contenuti e funzionalità (A/B testing)
        </p>
        <p>
            I servizi contenuti in questa sezione permettono al Titolare del Trattamento di tener traccia ed analizzare
            la risposta da parte dell’Utente, in termini di traffico o di comportamento,
            in relazione a modifiche della struttura, del testo o di qualunque altro componente di questo Sito Web.
        </p>
        <p className="privacy-subtitle mb-3 mt-4">
            Google Optimize (Google LLC)
        </p>
        <p className="privacy-subtext">
            Google Optimize è un servizio di A/B testing fornito da Google LLC ("Google"). Google potrebbe utilizzare i
            Dati Personali per contestualizzare e personalizzare gli annunci del suo
            network pubblicitario.<br/>
            Dati Personali trattati: Cookie e Dati di utilizzo.<br/><br/>

            Luogo del trattamento: Stati Uniti – Privacy Policy. Soggetto aderente al Privacy Shield
        </p>
        <p className="privacy-title mt-5 mb-4">
            Targeting e Pubblicità
        </p>
        <p>
            Questo Sito Web usa Cookie o altri Identificatori per fornire contenuti commerciali personalizzati in base
            al comportamento dell'Utente e per gestire, fornire e tenere traccia degli
            annunci pubblicitari.
        </p>
        <p className="privacy-subtitle mb-3 mt-4">
            Pubblicità
        </p>
        <p className="privacy-subtext">
            Questo tipo di servizi consentono di utilizzare i Dati dell’Utente per finalità di comunicazione commerciale
            in diverse forme pubblicitarie, quali il banner, anche in relazione agli
            interessi dell’Utente.<br/>
            Ciò non significa che tutti i Dati Personali vengano utilizzati per questa finalità. Dati e condizioni di
            utilizzo sono indicati di seguito.<br/>
            Alcuni dei servizi di seguito indicati potrebbero utilizzare Cookie per identificare l’Utente o utilizzare
            la tecnica del behavioral retargeting, ossia visualizzare annunci
            pubblicitari personalizzati in base agli interessi e al comportamento dell’Utente, rilevati anche al di
            fuori di questo Sito Web. Per avere maggiori informazioni in merito, ti
            suggeriamo di verificare le informative privacy dei rispettivi servizi.<br/>
            In aggiunta alle possibilità di effettuare l'opt-out offerte dai servizi di seguito riportati, l'Utente può
            optare per l'esclusione rispetto alla ricezione dei cookie relativi ad un
            servizio terzo, visitando la pagina di opt-out del Network Advertising Initiative.
        </p>
        <p className="privacy-subtitle mb-3 mt-4">
            Google AdSense (Google LLC)
        </p>
        <p className="privacy-subtext">
            Google AdSense è un servizio di advertising fornito da Google LLC. Questo servizio usa il Cookie
            “DoubleClick”, che traccia l’utilizzo di questo Sito Web ed il comportamento
            dell’Utente in relazione agli annunci pubblicitari, ai prodotti e ai servizi offerti.<br/>
            L’Utente può decidere in qualsiasi momento di disabilitare tutti i cookie DoubleClick andando su:
            Impostazioni Annunci.<br/>
            Dati Personali trattati: Cookie e Dati di utilizzo.<br/><br/>
            Luogo del trattamento: Stati Uniti – Privacy Policy – Opt Out. Soggetto aderente al Privacy Shield.
        </p>
        <p className="privacy-subtitle mb-3 mt-4">
            Facebook Audience Network (Facebook, Inc.)
        </p>
        <p className="privacy-subtext">
            Facebook Audience Network è un servizio pubblicitario fornito da Facebook, Inc. Per una comprensione
            dell'utilizzo dei dati da parte di Facebook, si prega di consultare la normativa
            sui dati di Facebook. Per permettere il funzionamento di Facebook Audience Network, questo Sito Web può
            utilizzare alcuni identificatori per dispositivi mobili (tra cui Android
            Advertising ID o Advertising Identifier per OS) ovvero tecnologie simili ai cookie. Tra le modalità
            attraverso cui Audience Network propone messaggi pubblicitari all’Utente, vi è anche
            l’utilizzo delle preferenze pubblicitarie di quest’ultimo. L’Utente può controllare la condivisione delle
            proprie preferenze pubblicitarie all’interno delle impostazioni Ad di
            Facebook. L’Utente può effettuare l’opt-out da alcune funzioni di Audience Network targeting mediante le
            impostazioni del proprio dispositivo. Ad esempio, può modificare le
            impostazioni sulla pubblicità disponibili per i dispositivi mobili, oppure seguire le istruzioni applicabili
            ad Audience Network eventualmente presenti all’interno di questa privacy
            policy.<br/>
            Dati Personali trattati: Cookie, Dati di utilizzo e identificatori univoci di dispositivi per la pubblicità
            (Google Advertiser ID o identificatore IDFA, per esempio).<br/><br/>
            Luogo del trattamento: Stati Uniti – Privacy Policy – Opt Out. Soggetto aderente al Privacy Shield.
        </p>
        <p className="privacy-title mt-5 mb-4">
            Remarketing e behavioral targeting
        </p>
        <p>
            Questo tipo di servizi consente a questo Sito Web ed ai suoi partner di comunicare, ottimizzare e servire
            annunci pubblicitari basati sull'utilizzo passato di questo Sito Web da parte
            dell'Utente.<br/>
            Questa attività viene effettuata tramite il tracciamento dei Dati di Utilizzo e l'uso di Cookie,
            informazioni che vengono trasferite ai partner a cui l'attività di remarketing e
            behavioral targeting è collegata.<br/>
            In aggiunta alle possibilità di effettuare l'opt-out offerte dai servizi di seguito riportati, l'Utente può
            optare per l'esclusione rispetto alla ricezione dei cookie relativi ad un
            servizio terzo, visitando la pagina di opt-out del Network Advertising Initiative.
        </p>
        <p className="privacy-subtitle mb-3 mt-4">
            Pubblico personalizzato di Facebook (Facebook, Inc.)
        </p>
        <p className="privacy-subtext">
            Pubblico personalizzato di Facebook è un servizio di remarketing e di targeting comportamentale fornito da
            Facebook, Inc. che collega l'attività di questo Sito Web con la rete
            pubblicitaria di Facebook. Gli Utenti possono scegliere di non utilizzare i cookie di Facebook per la
            personalizzazione degli annunci visitando questa pagina di opt-out.<br/>
            Dati Personali trattati: Cookie e email.<br/><br/>
            Luogo del trattamento: USA – Privacy Policy – Opt Out. Soggetto aderente al Privacy Shield.
        </p>
        <p className="privacy-subtitle mb-3 mt-4">
            Facebook Remarketing (Facebook, Inc.)
        </p>
        <p className="privacy-subtext">
            Facebook Remarketing è un servizio di remarketing e behavioral targeting fornito da Facebook, Inc. che
            collega l'attività di questo Sito Web con il network di advertising Facebook.<br/>
            Dati Personali trattati: Cookie e Dati di utilizzo.<br/><br/>
            Luogo del trattamento: USA – Privacy Policy – Opt Out. Soggetto aderente al Privacy Shield.
        </p>
        <p className="privacy-subtitle mb-3 mt-4">
            Remarketing con Google Analytics (Google Inc.)
        </p>
        <p className="privacy-subtext">
            Remarketing con Google Analytics è un servizio di remarketing e behavioral targeting fornito da Google LLC
            oppure da Google Ireland Limited, a seconda della posizione in cui questo Sito Web viene utilizzata, che
            collega l'attività di tracciamento effettuata da Google Analytics e dai suoi Cookie con il network di
            advertising Google Ads ed il Cookie Doubleclick.<br/>
            Dati Personali trattati: Cookie e Dati di utilizzo.<br/><br/>
            Luogo del trattamento: USA – Privacy Policy – Opt Out. Soggetto aderente al Privacy Shield.
        </p>
        <p className="privacy-subtitle mb-3 mt-4">
            Remarketing Google Ads (Google LLC)
        </p>
        <p className="privacy-subtext">
            Remarketing Google Ads è un servizio di remarketing e behavioral targeting fornito da Google LLC che collega
            l'attività di questo Sito Web con il network di advertising Google Ads ed il Cookie DoubleClick. Gli Utenti
            possono scegliere di non utilizzare i cookie di Google per la personalizzazione degli annunci visitando le
            Impostazioni annunci di Google.<br/>
            Dati Personali trattati: Cookie e Dati di utilizzo.<br/><br/>
            Luogo del trattamento: Stati Uniti – Privacy Policy – Opt Out. Soggetto aderente al Privacy Shield.
        </p>
        <p className="privacy-title mt-5 mb-4">
            Come prestare o revocare il consenso
        </p>
        <p>
            Gli Utenti possono prestare o revocare il proprio consenso all’uso dei Cookie e altri Identificatori
            impostando di conseguenza le proprie preferenze all’interno dell’infomativa sui cookie o aggiornando tali
            preferenze tramite l’apposito widget per le impostazioni di consenso, se disponibile.<br/><br/>
            In aggiunta, gli Utenti possono gestire le proprie preferenze relative agli Identificatori direttamente
            dalle impostazioni del proprio dispositivo e prevenire - per esempio - l'accettazione di Identificatori di
            terze parti. Tramite le impostazioni del browser o del dispositivo, è inoltre possibile eliminare gli
            Identificatori salvati in passato, inclusi quelli usati per memorizzare il consenso iniziale prestato
            dall’Utente. Gli Utenti possono, trovare informazioni su come gestire gli Identificatori con alcuni dei
            browser più diffusi, ad esempio, ai seguenti indirizzi: Google Chrome, Mozilla Firefox, Apple Safari e
            Microsoft Internet Explorer.<br/><br/>
            Con riferimento agli Identificatori impiegati da terze parti, gli Utenti possono gestire le proprie
            impostazioni e revocare il proprio consenso visitando il relativo link di opt out (qualora disponibile),
            utilizzando gli strumenti descritti nella privacy policy della terza parte o contattando direttamente la
            stessa.<br/><br/>
            Fermo restando quanto precede, si informano gli Utenti della possibilità di avvalersi delle informazioni
            fornite da YourOnlineChoices (EU), the Network Advertising Initiative (USA) and the Digital Advertising
            Alliance (USA), DAAC (Canada), DDAI (Giappone) o altri servizi analoghi. Con questi servizi è possibile
            gestire le preferenze di tracciamento della maggior parte degli strumenti pubblicitari. Il Titolare,
            pertanto, consiglia agli Utenti di utilizzare tali risorse in aggiunta alle informazioni fornite dal
            presente documento.
        </p>
        <p className="privacy-title mt-5 mb-4">
            Come prestare o revocare il consenso
        </p>
        <p>
            Plesh S.p.A., Corso Appio Claudio 213, 10146 Torino (TO)<br/><br/>
            Indirizzo email del Titolare: <a href="mailto:info@plesh.co">info@plesh.co</a><br/><br/>
            Dal momento che l’uso di Cookie e altri Identificatori operato da terze parti tramite i servizi utilizzati
            all'interno di questo Sito Web non può essere tecnicamente controllato dal Titolare, ogni riferimento
            specifico ad Identificatori utilizzati da terze parti è da considerarsi indicativo. Per ottenere
            informazioni complete, l’Utente è invitato a consultare la privacy policy degli eventuali servizi terzi
            elencati in questo documento.<br/><br/>
            Vista l'oggettiva complessità di identificazione delle tecnologie basate sui Cookie e altri Identificatori,
            gli Utenti sono invitati a contattare il Titolare qualora volessero ricevere qualunque approfondimento
            relativo all'utilizzo di tali Identificatori tramite questo Sito Web.<br/><br/>
        </p>
        <p className="privacy-title mt-5 mb-4">
            Definizioni e riferimenti legali
        </p>
        <p className="privacy-subtitle mb-3 mt-4">Interessato</p>
        <p className="privacy-subtext">La persona fisica cui si riferiscono i Dati Personali.</p>

        <p className="privacy-subtitle mb-3 mt-4">Responsabile del Trattamento (o Responsabile)</p>
        <p className="privacy-subtext">La persona fisica, giuridica, la pubblica amministrazione e qualsiasi altro ente
            che tratta dati personali per conto del Titolare, secondo quanto esposto nella
            presente privacy policy.</p>
        <p className="privacy-subtitle mb-3 mt-4">
            Titolare del Trattamento (o Titolare)
        </p>
        <p className="privacy-subtext">
            La persona fisica o giuridica, l’autorità pubblica, il servizio o altro organismo che, singolarmente o
            insieme ad altri, determina le finalità e i mezzi del trattamento di dati
            personali e gli strumenti adottati, ivi comprese le misure di sicurezza relative al funzionamento ed alla
            fruizione di questo Sito. Il Titolare del Trattamento, salvo quanto
            diversamente specificato, è il titolare di questo Sito.
        </p>
        <p className="privacy-subtitle mb-3 mt-4">
            Questo Sito
        </p>
        <p className="privacy-subtext">
            Lo strumento hardware o software mediante il quale sono raccolti e trattati i Dati Personali degli Utenti.
        </p>
        <p className="privacy-subtitle mb-3 mt-4">
            Servizio
        </p>
        <p className="privacy-subtext">
            Il Servizio o i Servizi forniti da Plesh anche per mezzo di questo Sito Web così come definito nei relativi
            termini (se presenti) su questo sito.
        </p>
        <p className="privacy-subtitle mb-3 mt-4">Unione Europea (o UE)</p>
        <p className="privacy-subtext">Salvo ove diversamente specificato, ogni riferimento all’Unione Europea contenuto
            in questo documento si intende esteso a tutti gli attuali stati membri
            dell’Unione Europea e dello Spazio Economico Europeo.</p>
        <p className="privacy-subtitle mb-3 mt-4">Cookie</p>
        <p className="privacy-subtext">Piccola porzione di dati conservata all’interno del dispositivo dell’Utente.</p>
        <p className="privacy-subtitle mb-3 mt-4">Riferimenti legali</p>
        <p className="privacy-subtext">
            La presente informativa privacy è redatta sulla base degli artt. 13 e 14 del Regolamento (UE) 2016/679.
        </p>
    </Container>
)

export default Privacy
