import React from "react";
import {Button, Container, Stack, useMediaQuery} from "@mui/material";
import logo from "../assets/images/logo_header.png";
import CookiesEPrivacy from "./Footer/CookiesEPrivacy";
import {useTranslation} from "react-i18next";

const Footer = () => {
    const matches = useMediaQuery('(min-width:1100px)');
    const {t} = useTranslation()
    return (
        <Container maxWidth={"xxl"} sx={{pb: 2}}>
            <Stack direction={matches ? "row" : "column"} justifyContent={!matches ? 'center' : "space-around"}
                   alignContent={"center"} alignItems={!matches ? 'center' : 'flex-end'}>
                <div style={{textAlign: matches ? 'left' : 'center'}}>
                    <img style={{maxWidth: '228px', maxHeight: '49px'}} src={logo}/>
                    {!matches && <CookiesEPrivacy matches={matches}/>}
                    <p style={{
                        opacity: 0.4,
                        fontFamily: 'Forever Grotesk',
                        fontWeight: 400,
                        fontSize: 'min(3.5vw, 1.313rem)',
                        margin: '0'
                    }}>{t('footer.product')} <a style={{color:'#fff', fontWeight:'bold', textDecoration:'none'}} href={'https://plesh.co'} target={'_blank'}>Plesh</a></p>
                </div>

                {matches && <CookiesEPrivacy/>}
            </Stack>
        </Container>
    )
}

export default Footer
