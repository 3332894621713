import React from "react";
import {Card, Container, Grid} from "@mui/material";
import CardInterazione from "./ComeInteragiscono/CardInterazione";
import ComeInteragisconoTitle from "./ComeInteragiscono/ComeInteragisconoTitle";
import {useTranslation} from "react-i18next";

const ComeInteragiscono = ({features}) => {
    const {t} = useTranslation()
    const cardContent = [
        {
            image:"quiz",
            bkg: "rgba(122,98,39,0.25)",
            title: t('come-interagiscono.cards.1.title'),
            content: t('come-interagiscono.cards.1.content')
        },
        {
            image:"polls",
            bkg: "rgba(185,25,104,0.18)",
            title: t('come-interagiscono.cards.2.title'),
            content: t('come-interagiscono.cards.2.content')
        },
        {
            image:"qna",
            bkg: "rgba(232,100,102,0.18)",
            title: t('come-interagiscono.cards.3.title'),
            content: t('come-interagiscono.cards.3.content')
        },
        {
            image:"selfie-wall",
            bkg: "rgba(229,10,10,0.18)",
            title: t('come-interagiscono.cards.4.title'),
            content: t('come-interagiscono.cards.4.content')
        },
        {
            image:"wordcloud",
            bkg: "rgba(232,100,68,0.25)",
            title: t('come-interagiscono.cards.5.title'),
            content: t('come-interagiscono.cards.5.content')
        },
        {
            image:"applausometro",
            bkg: "rgba(185,25,104,0.18)",
            title: t('come-interagiscono.cards.6.title'),
            content: t('come-interagiscono.cards.6.content')
        },
    ]
    return(
        <Container style={{maxWidth: "1400px"}} sx={{pt: 6}} ref={features}>
            <ComeInteragisconoTitle/>
            <Grid container spacing={4} alignItems={'stretch'}>
                {cardContent.map(card => <CardInterazione card={card}/>)}
            </Grid>
        </Container>
    )
}

export default ComeInteragiscono
