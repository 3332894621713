import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
// eslint-disable-next-line
import "swiper/css/bundle";
import {BrowserRouter} from "react-router-dom";
import '../src/services/i18n'
import {HelmetProvider} from "react-helmet-async";

ReactDOM.render(
    <React.Suspense fallback={'loading...'}>
        <BrowserRouter>
            <App/>
        </BrowserRouter>
    </React.Suspense>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
