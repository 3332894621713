import React from 'react'
import {Box, Grid, Stack} from "@mui/material";

const ComeText = ({index, title, text}) => {
  return(
    <Grid item xs={4} style={{textAlign:'left', padding:'2rem'}}>
        <Stack style={{width:'70px',height:'70px', border:'3px solid #E85D74', borderRadius:'5rem', color:'#E85D74', textAlign:'center'}} justifyContent={"center"}>
            <p style={{margin:'0', fontFamily:'Forever Grotesk', fontWeight:700, fontSize:'2.063rem'}}>{index}</p>
        </Stack>
        <p style={{fontFamily:'Proto Grotesk', fontSize:'2.188rem', color:'#E85D74', margin:'1rem 0 0 0 '}}>{title}</p>
        <p style={{fontFamily:'Forever Grotesk', fontSize:'1.313rem', color:'#fff', opacity:'0.82',}}>{text}</p>
    </Grid>
  )
}

export default ComeText
