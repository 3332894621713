import React from "react";
import {useTranslation} from "react-i18next";

const ComeInteragisconoTitle = () => {
    const {t} = useTranslation()
    return (
        <div style={{paddingBottom: '2rem'}}>
            <p style={{
                fontFamily: 'Proto Grotesk',
                fontSize: '2.688rem',
                fontWeight: 400,
                textAlign: 'center',
                lineHeight: '2.813rem',
                marginBottom: '1rem'
            }}>
                {t('come-interagiscono.title')}
            </p>
            <p style={{
                opacity: '0.5',
                padding: '0 1rem',
                maxWidth: '580px',
                margin: 'auto',
                fontFamily: 'Forever Grotesk',
                fontSize: '1.313rem',
                fontWeight: 400,
                textAlign: 'center',
                lineHeight: '1.813rem'
            }}>
                {t('come-interagiscono.subtitle-1')}
                <br/>
                {t('come-interagiscono.subtitle-2')}
            </p>
        </div>
    )
}

export default ComeInteragisconoTitle
