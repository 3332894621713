import React from "react";
import {Card, CardContent, Stack} from "@mui/material";
import {SwiperSlide} from "swiper/react";

const ComeCard = ({text, title, index, image}) => (

        <Card variant={'swipe'} style={{width:'100%', minHeight:'500px'}}>
            <div style={{padding:'30px 0 0 0'}}>
                <img style={{height:'175px'}} src={require(`../../assets/images/${image}.png`)}/>
            </div>
            <CardContent style={{maxWidth:'360px', margin:'auto'}}>
                <Stack style={{width:'38px',height:'38px', border:'3px solid #E85D74', borderRadius:'5rem', color:'#E85D74', textAlign:'center', margin:'auto auto 1rem auto',}} alignContent={"center"} justifyContent={"center"}>
                    <p style={{margin:'auto', fontFamily:'Forever Grotesk', fontWeight:700, fontSize:'1.438rem'}}>{index}</p>
                </Stack>
                <p style={{fontSize: '1.75rem', fontWeight:400, fontFamily:'Proto Grotesk', lineHeight:'2.438rem', marginTop:'0', marginBottom:'7px', color:'#E85D74'}}>{title}</p>
                <p style={{fontSize: '1.313rem', lineHeight:'1.5rem', opacity:'0.6', fontWeight:400, fontFamily:'Forever Grotesk', margin:'0'}}>{text}</p>
            </CardContent>
        </Card>


)

export default ComeCard
