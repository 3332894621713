import logo from './logo.svg';
import './App.css';
import Home from "./pages/Home";
import {Box, CssBaseline, ThemeProvider, useMediaQuery} from "@mui/material";
import {pleshDark} from "./theme/pleshDark";
import Header from "./components/Header";
import logo_mobile_big from "./assets/images/logo_header_mobile_big_by_plesh.png";
import * as React from "react";
import {useRef, useTransition} from "react";
import {Route, Routes} from "react-router-dom";
import Privacy from "./pages/Privacy";
import RichiediDemo from "./pages/RichiediDemo";
import {LangSwitcher} from "./components/LangSwitcher";
import {LanguageRoute} from "./components/LanguageRoute";
import {Helmet, HelmetProvider} from "react-helmet-async";

function App() {

    const helmetContext = {};

    return (
        <HelmetProvider context={helmetContext}>
            <Helmet>
                <title>2ndStage - Attiva la tua Audience</title>
                <link rel="alternate" hrefLang="it" href="https://2ndstage.app"/>

                <meta lang="it" name="description"
                      content="2ndStage è la tecnologia interattiva che permette allo spettatore di essere protagonista dello show, utilizzando il proprio smartphone. Coinvolgi e monetizza il tuo pubblico con un’esperienza unica e straordinaria."/>
                <meta lang="it" itemProp="name" content="2ndStage - Attiva la tua Audience"/>
                <meta lang="it" itemProp="description"
                      content="2ndStage è la tecnologia interattiva che permette allo spettatore di essere protagonista dello show, utilizzando il proprio smartphone. Coinvolgi e monetizza il tuo pubblico con un’esperienza unica e straordinaria."/>
                <meta lang="it" itemProp="image" content="https://2ndstage.app/preview.jpg"/>
                <meta lang="it" property="og:url" content="https://2ndstage.app"/>
                <meta lang="it" property="og:type" content="website"/>
                <meta lang="it" property="og:title" content="2ndStage - Attiva la tua Audience"/>
                <meta lang="it" property="og:description"
                      content="2ndStage è la tecnologia interattiva che permette allo spettatore di essere protagonista dello show, utilizzando il proprio smartphone. Coinvolgi e monetizza il tuo pubblico con un’esperienza unica e straordinaria."/>
                <meta lang="it" property="og:image"
                      content="https://2ndstage.app/preview.jpg"/>
                <meta lang="it" name="twitter:card" content="summary_large_image"/>
                <meta lang="it" name="twitter:title"
                      content="2ndStage - Attiva la tua Audience"/>
                <meta lang="it" name="twitter:description"
                      content="2ndStage è la tecnologia interattiva che permette allo spettatore di essere protagonista dello show, utilizzando il proprio smartphone. Coinvolgi e monetizza il tuo pubblico con un’esperienza unica e straordinaria."/>
                <meta lang="it" name="twitter:image"
                      content="https://2ndstage.app/preview.jpg"/>
            </Helmet>
            <ThemeProvider theme={pleshDark}>
                <CssBaseline/>
                <div className="App">
                    <Routes>
                        <Route element={<LanguageRoute/>}>
                            <Route path={'/:lang'} element={<Home/>}/>
                            <Route path={'/:lang/privacy-policy'} element={<Privacy/>}/>
                            <Route path={'/:lang/grazie-informazioni-2ndstage'} element={<RichiediDemo/>}/>
                        </Route>
                        <Route path={'/'} element={<Home/>}/>
                        <Route path={'/grazie-informazioni-2ndstage'} element={<RichiediDemo/>}/>
                    </Routes>
                </div>
            </ThemeProvider>
        </HelmetProvider>
    );
}

export default App;
