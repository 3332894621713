import React from "react";
import {Button, Stack, useMediaQuery} from "@mui/material";
import {useTranslation} from "react-i18next";

const CookiesEPrivacy = ({matches}) => {
    const {t} = useTranslation()
  return(
      <Stack direction={"row"} justifyContent={matches ? "space-between" : 'center'} sx={{my: {xs: 1, md:0}}} spacing={8} alignItems={"center"}>
          <a href={'/privacy-policy'} style={{color:'#fff', textDecoration:'none',opacity:0.4, fontFamily:'Forever Grotesk', fontWeight:400, fontSize:'1.313rem'}}>Cookies</a>
          <a href={'/privacy-policy'} style={{color:'#fff', textDecoration:'none',opacity:0.4, fontFamily:'Forever Grotesk', fontWeight:400, fontSize:'1.313rem'}}>Privacy</a>
          <Button target={'_blank'} href={'/request-a-demo'} sx={{maxHeight: 55, display: {xs: 'none', lg:'block', textTransform:'uppercase'}}} variant={"contained"} style={{color:'white'}}>{t('shared.cta')}</Button>
      </Stack>
  )
}

export default CookiesEPrivacy
