import React from "react";
import Typewriter from 'typewriter-effect';
import {useTranslation} from "react-i18next";
import {Box} from "@mui/material";

const Typewrite = () => {
    const {t} = useTranslation()
  return(
      <Box sx={{px:2}}>
          <div style={{
              color: '#E85C75',
              fontFamily: 'Proto Grotesk',
              fontSize: 'min(7.5vw, 2.688rem)', lineHeight: 'min(9.3vw, 2.813rem)',
              fontWeight: 400,
              minHeight:'100px'
          }}>
              <Typewriter
                  options={{
                      autoStart: true,
                      loop: true
                  }}
                  onInit={(typewriter) => {
                      typewriter
                          .typeString(t('compagno.event'))
                          .pauseFor(2500)
                          .deleteAll()
                          .typeString(t('compagno.concert'))
                          .pauseFor(2500)
                          .deleteAll()
                          .typeString(t('compagno.show'))
                          .pauseFor(2500)
                          .deleteAll()
                          .typeString(t('compagno.sport'))
                          .pauseFor(2500)
                          .deleteAll()
                          .start();
                  }}
              />
          </div>
      </Box>
  )
}

export default Typewrite
