import React from "react";
import {Box, Container, Grid, useMediaQuery, useTheme} from "@mui/material";
import play from '../assets/images/play-button.png'
import rivolto from '../assets/images/rivolto-visual.png'
import rivoltoMobile from '../assets/images/rivolto-visual-mobile.png'
import {Trans, useTranslation} from "react-i18next";
import ComeTitle from "./Come/ComeTitle";
import {Swiper, SwiperSlide} from "swiper/react";
import {Pagination} from "swiper";
import ComeCard from "./Come/ComeCard";

const RivoltoAChi = ({panoramica}) => {
    const {t} = useTranslation()
    const theme = useTheme()
    const matches = useMediaQuery(theme.breakpoints.up('md'));

    return (
        <Box ref={panoramica}>
            <Box sx={{pt: {xs:0, md:9}, pb:{xs:5, md:9}, px:{xs:2, md:0}, width:'min(100rem, 100%)', m:'auto'}}>
                <Grid container alignItems={"center"} spacing={4}>
                    <Grid item xs={12} md={8}>
                        <Box sx={{pt:5}}>
                            <Swiper
                                slidesPerView={1}
                                spaceBetween={10}
                                className="mySwiper"
                                pagination={{
                                    clickable: true,
                                }}
                                modules={[Pagination]}
                                style={{
                                    padding:  `1rem ${matches ? '3rem' : '2rem'}`,
                                    "--swiper-pagination-color": "#E85D74",
                                    "--swiper-pagination-bullet-size":"18px",
                                    "--swiper-pagination-bullet-border-color":"#E85D74",
                                    "--swiper-pagination-bullet-inactive-color": "#4C4C7E",
                                    "--swiper-pagination-bullet-inactive-opacity": 1
                                }}
                            >
                                {Array.apply(null, Array(4)).map((t, i) =>
                                    <SwiperSlide key={i} style={{minHeight:matches ? 'min(40rem,50vw)':'max(20rem, 60vw)'}}>
                                        <div style={{padding: '30px 0 0 0'}}>
                                            <img style={{width: '100%'}}
                                                 src={require(`../assets/images/rivolto-a-chi/${i+1}.png`)}/>
                                        </div>
                                    </SwiperSlide>)
                                }
                            </Swiper>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={4} style={{textAlign:"left"}}>
                        <Box sx={{px:{xs:2, md:0}}}>
                            <p style={{fontFamily: 'Proto Grotesk', fontWeight: '400', fontSize: '2.688rem', lineHeight:'3rem'}}>
                                {t('rivolto.title')}
                            </p>
                            <p style={{fontFamily: 'Forever Grotesk', fontWeight: '400', fontSize: '1.313rem', lineHeight:'1.5rem', opacity:'0.7'}}>
                                <Trans i18nKey="rivolto.text"/>
                            </p>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    )
}

export default RivoltoAChi
