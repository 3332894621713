import React from "react";
import main from "../assets/images/main.png";
import card1 from "../assets/images/main-visual/card1.png"
import card2 from "../assets/images/main-visual/card2.png"
import card3 from "../assets/images/main-visual/card3.png"
import card4 from "../assets/images/main-visual/card4.png"
import icone from "../assets/images/main-visual/icone.png"
import mano from "../assets/images/main-visual/mano.png"
import ondaDx from "../assets/images/main-visual/ondaDx.png"
import ondaSx from "../assets/images/main-visual/ondaSx.png"
import ondaMobile from '../assets/images/main-visual/ondaMobile.png'
import {Box, Stack, useMediaQuery} from "@mui/material";

const MainVisual = () => {
    const tablet = useMediaQuery('(max-width:1100px)');
    const mobile = useMediaQuery('(max-width:767px)');
    return (
        <div style={{width: '100%', position: 'relative', height: '100%'}}>
            {!tablet &&
                <div style={{position: 'absolute', width: '100%', bottom: '0', left: '0', zIndex: 99}}>
                    <img className={'icone'} src={icone} style={{width: '100%'}}/>
                </div>
            }
            {!mobile && <div style={{position: 'absolute', width: '100%', zIndex: '-1'}}>
                <div style={{position: 'relative', width: '100%'}}>
                    <img className={'card1'} src={card1} style={{width: '14vw', position: 'absolute', left: '22vw'}}/>
                    <img className={'card2'} src={card2}
                         style={{width: '14vw', position: 'absolute', left: '30vw', top: '5rem'}}/>
                    <img className={'card4'} src={card4}
                         style={{width: '25vw', position: 'absolute', left: '60vw', top: '-4rem'}}/>
                    <img className={'card3'} src={card3}
                         style={{width: '25vw', position: 'absolute', left: '50vw', top: '5rem'}}/>
                </div>
            </div>}
            {!mobile && <Stack direction={"row"} justifyContent={"center"} width={'100%'} position={"absolute"} height={'100%'}>
                <Box className={'mano'}>
                    <img style={{objectFit:'contain', objectPosition:'bottom', width:'100%', height:'100%'}} src={mano}/>
                </Box>
            </Stack>}
            <Stack direction={"row"} justifyContent={"space-between"} width={'100%'} sx={{display: {lg:'flex', xs:'none'}}}>
                <img className={'ondaSx'} style={{maxWidth: '445px'}} src={ondaSx}/>
                <img className={'ondaDx'} style={{maxWidth: '445px'}} src={ondaDx}/>
            </Stack>
            <Stack direction={"row"} justifyContent={"space-between"} width={'100%'} sx={{display: {md:'flex', xs:'none', lg: 'none'}}}>
                <img className={'ondaSx'} style={{maxWidth: '345px'}} src={ondaSx}/>
                <img className={'ondaDx'} style={{maxWidth: '345px'}} src={ondaDx}/>
            </Stack>
            <Box width={'100%'} sx={{display: {md:'none', xs:'flex'}}} height={'100%'} style={{bottom:'0', textAlign:'center', justifyContent:'center'}}>
                <Box className={'mano'}>
                    <img style={{objectFit:'contain', objectPosition:'bottom', width:'100%', height:'100%'}} src={mano}/>
                </Box>
                <img className={'ondaMobile'} style={{width: '100%'}} src={ondaMobile}/>
            </Box>
        </div>
    )

}

export default MainVisual
