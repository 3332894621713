import * as React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import logo from '../assets/images/logo_header_by_plesh.png'

import useScrollTrigger from '@mui/material/useScrollTrigger';
import Slide from '@mui/material/Slide';
import {Box, Button, Container, Stack, useMediaQuery} from "@mui/material";
import {Link, useLocation} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {LangSwitcher} from "./LangSwitcher";

function HideOnScroll(props) {
    const {children, window} = props;
    const trigger = useScrollTrigger({
        target: window ? window() : undefined,
    });

    return (
        <Slide appear={false} direction="down" in={!trigger}>
            {children}
        </Slide>
    );
}

HideOnScroll.propTypes = {
    children: PropTypes.element.isRequired,
    window: PropTypes.func,
};


export default function Header(props) {
    const location = useLocation()
    const {t} = useTranslation()
    return (
        <HideOnScroll {...props}>
            <AppBar>
                <Toolbar>
                    <Container maxWidth={"xxl"}>
                        <Stack direction={"row"} justifyContent={"space-around"} alignContent={"center"} alignItems={'center'}>
                            <Box component={Link} to={'/'} sx={{maxWidth:'228px',p:2}}>
                                <img style={{width:'100%'}} src={logo} />
                            </Box>
                            {!location.pathname.includes('/privacy-policy') && !location.pathname.includes('/request-a-demo')  && <Stack direction={"row"} justifyContent={"space-between"} spacing={6}
                                    alignContent={"center"} alignItems={"center"}>
                                <p style={{
                                    cursor: 'pointer',
                                    opacity: 0.4,
                                    fontFamily: 'Forever Grotesk',
                                    fontWeight: 400,
                                    fontSize: '1.313rem'
                                }} onClick={props.handlePanoramica}>
                                    {t('header.overview')}
                                </p>
                                <p style={{
                                    cursor: 'pointer',
                                    opacity: 0.4,
                                    fontFamily: 'Forever Grotesk',
                                    fontWeight: 400,
                                    fontSize: '1.313rem'
                                }} onClick={props.handleFeatures}>
                                    {t('header.features')}
                                </p>
                                <p style={{
                                    cursor: 'pointer',
                                    opacity: 0.4,
                                    fontFamily: 'Forever Grotesk',
                                    fontWeight: 400,
                                    fontSize: '1.313rem'
                                }} onClick={props.handleComeFunziona}>
                                    {t('header.how')}
                                </p>
                                <Stack justifyContent={'center'} alignItems={'center'} direction={'row'} spacing={2}>
                                    <Button sx={{maxHeight: 45}} variant={"outlined"}
                                            onClick={props.handleProvalo}>
                                        {t('header.try')}
                                    </Button>
                                    <Box>
                                        <LangSwitcher/>
                                    </Box>
                                </Stack>
                            </Stack>}
                        </Stack>
                    </Container>
                </Toolbar>
            </AppBar>
        </HideOnScroll>
    );
}
